var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"menu",attrs:{"id":"menu"}},[_c('div',{staticClass:"menu__part menu__part--left",attrs:{"id":"menu-part-1"}},[_c('div',{staticClass:"menu__options"},_vm._l((_vm.getCollectionItems(
          _vm.menuOptions,
          0,
          _vm.menuOptions.length / 2
        )),function(menuOption){return _c('router-link',{key:menuOption,staticClass:"menu__option",attrs:{"to":_vm.formatStringInKebabCase(menuOption)}},[_vm._v(_vm._s(_vm.$t(("headers." + (_vm.formatStringInKebabCase(menuOption))))))])}),1)]),_vm._m(0),_c('div',{staticClass:"menu__part menu__part--right",attrs:{"id":"menu-part-2"}},[_c('div',{staticClass:"menu__options"},_vm._l((_vm.getCollectionItems(
          _vm.menuOptions,
          _vm.menuOptions.length / 2,
          _vm.menuOptions.length
        )),function(menuOption){return _c('router-link',{key:menuOption,staticClass:"menu__option",attrs:{"to":_vm.formatStringInKebabCase(menuOption)}},[_vm._v(_vm._s(_vm.$t(("headers." + (_vm.formatStringInKebabCase(menuOption))))))])}),1)])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"menu__logo-container"},[_c('img',{staticClass:"menu__logo",attrs:{"src":require("@/assets/images/logo/ToDustSigillSand.png"),"alt":"To Dust logotype"}})])}]

export { render, staticRenderFns }