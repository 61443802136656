<template>
  <nav>
    <MenuDesktop class="menu-desktop" :menuOptions="menuOptions" />
    <MenuMobile class="menu-mobile" :menuOptions="menuOptions" />
  </nav>
</template>

<script>
import MenuDesktop from './MenuDesktop.vue';
import MenuMobile from './MenuMobile.vue';

export default {
  name: 'Menu',
  components: {
    MenuDesktop,
    MenuMobile,
  },
  data() {
    return {
      menuIsOpen: false,
      menuOptions: ['Home', 'News', 'About', 'Music', 'Store', 'Contact'],
    };
  },
  methods: {
    handleChangeMenuIconState(event) {
      this.toggleMenu('expandableMenuOptions', event);
    },
    toggleMenu(menuId, open) {
      this.setMenuIsOpen(open);
      this.toggleExpandableElement(menuId);
    },
    setMenuIsOpen(value) {
      this.menuIsOpen = value;
    },
    toggleExpandableElement(expandableElementId) {
      const expandableElement = document.getElementById(expandableElementId);

      if (expandableElement.style.maxHeight) {
        expandableElement.style.maxHeight = null;
      } else {
        expandableElement.style.maxHeight = `${expandableElement.scrollHeight}px`;
      }
    },
  },
};
</script>

<style scoped lang="scss">
nav {
  width: 100%;
  font-family: Portmanteau;
}

@media (max-width: 749px) {
  .menu-desktop {
    display: none;
  }
}

@media (min-width: 750px) {
  .menu-mobile {
    display: none;
  }
}
</style>
