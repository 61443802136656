<template>
  <div id="menu" class="menu">
    <div id="menu-part-1" class="menu__part menu__part--left">
      <div class="menu__options">
        <router-link
          class="menu__option"
          v-for="menuOption in getCollectionItems(
            menuOptions,
            0,
            menuOptions.length / 2
          )"
          :key="menuOption"
          :to="formatStringInKebabCase(menuOption)"
          >{{
            $t(`headers.${formatStringInKebabCase(menuOption)}`)
          }}</router-link
        >
      </div>
    </div>

    <div class="menu__logo-container">
      <img
        class="menu__logo"
        src="@/assets/images/logo/ToDustSigillSand.png"
        alt="To Dust logotype"
      />
    </div>

    <div id="menu-part-2" class="menu__part menu__part--right">
      <div class="menu__options">
        <router-link
          class="menu__option"
          v-for="menuOption in getCollectionItems(
            menuOptions,
            menuOptions.length / 2,
            menuOptions.length
          )"
          :key="menuOption"
          :to="formatStringInKebabCase(menuOption)"
          >{{
            $t(`headers.${formatStringInKebabCase(menuOption)}`)
          }}</router-link
        >
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MenuDesktop',
  props: {
    menuOptions: Array,
  },
  methods: {
    formatStringInKebabCase(str) {
      return str.toLowerCase().replace(' ', '-');
    },
    getCollectionItems(collection, startIndex, numberOfItems) {
      return collection.slice(startIndex, numberOfItems);
    },
  },
};
</script>

<style scoped lang="scss">
.menu {
  height: 75px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  &__logo-container {
    width: 100px;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  &__logo {
    max-width: 100%;
    max-height: 100%;
  }

  &__part {
    flex-basis: 0;
    flex-grow: 3;
    display: flex;

    &--right {
      justify-content: flex-start;
    }

    &--left {
      justify-content: flex-end;
    }
  }

  &__option {
    margin: 0px 5px 0px 5px;

    text-decoration: none;
    color: #e4e1d4;
    filter: brightness(50%);

    font-size: 1.2em;
    font-family: Portmanteau;

    &:hover {
      transition: 0.3s;
      filter: brightness(100%);
    }
  }

  &__option:lang(ru) {
    font-family: Capture-it;
    font-size: 1.4em;
  }
}
</style>
