<template>
  <div class="mainPage">
    <header>
      <Menu />
    </header>

    <main class="viewContainer">
      <router-view />
    </main>

    <footer>
      <LanguageSelector />
      <img src="../assets/images/todust_email.png" alt="To Dust email" />
      <Copyright />
    </footer>
  </div>
</template>

<script>
import Menu from '@/components/Menu/Menu.vue';
import Copyright from '@/components/Copyright.vue';
import LanguageSelector from '@/components/LanguageSelector.vue';

export default {
  name: 'MainPage',
  components: {
    Menu,
    Copyright,
    LanguageSelector,
  },
};
</script>

<style>
.readFriendlyLayout {
  margin-left: auto;
  margin-right: auto;
  width: 90%;
  max-width: 600px;
}

.mainPage {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
}

header {
  position: fixed;
  top: 0px;
  width: 100%;
  z-index: 2;
  background-color: #121619;
}

.viewContainer {
  flex-grow: 1;
  background-color: #1b1e20;
  margin-top: 75px;
}

.viewContainer section:nth-child(2n) {
  background-color: #1e1e1f;
}

footer {
  text-align: center;
}
</style>
