<template>
  <div id="setLanguage" class="languageSelector">
    <span
      v-for="language in languages"
      :key="language"
      @click="switchLanguage(language)"
    >
      <img
        :src="getImagePath(language)"
        :alt="`${$t('view.index.language-option')}: ${language}`"
      />
    </span>
  </div>
</template>

<script>
export default {
  name: 'LanguageSelector',
  methods: {
    switchLanguage(language) {
      if (this.$i18n.locale !== language) {
        this.$i18n.locale = language;
        document.documentElement.lang = language;
        document.title = this.$i18n.t('title');
        this.$router.replace({ params: { locale: language } });
      }
    },
    getImagePath(language) {
      return require(`../assets/images/icons/language-icons/ikon-${language}_l.png`);
    },
  },
  data() {
    return {
      languages: process.env.VUE_APP_I18N_SUPPORTED_LOCALES.split(','),
    };
  },
};
</script>

<style scoped lang="scss">
.languageSelector * {
  background-color: transparent;
  border: none;
  margin: 5px 2px 5px 2px;
  *:hover {
    cursor: pointer;
  }
}
</style>
