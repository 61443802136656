<template>
  <div class="menu">
    <div class="menu__bar">
      <div class="menu__part"></div>
      <router-link to="/" class="menu__logo">
        <img
          class="menu__logo-image"
          src="@/assets/images/logo/ToDustLoggaSand.png"
          alt="To Dust logotype"
        />
      </router-link>
      <div class="menu__part">
        <MenuIcon
          v-bind:isOpen.sync="this.menuIsOpen"
          v-on:changeMenuIconState="handleChangeMenuIconState($event)"
        />
      </div>
    </div>
    <div
      id="expandableMenuOptions"
      class="menu__options"
      @click="toggleMenu('expandableMenuOptions', false)"
    >
      <router-link
        v-for="menuOption in menuOptions"
        v-bind:key="menuOption"
        :to="convertMenuOptionToRoute(menuOption)"
        class="menu__option"
      >
        {{ $t(`headers.${menuOption.toLowerCase()}`) }}
      </router-link>
    </div>
  </div>
</template>

<script>
import MenuIcon from '@/components/Menu/MenuIcon.vue';

export default {
  name: 'MenuMobile',
  components: {
    MenuIcon,
  },
  props: {
    menuOptions: Array,
  },
  data() {
    return {
      menuIsOpen: false,
    };
  },
  methods: {
    convertMenuOptionToRoute(menuOption) {
      return menuOption.toLowerCase().replace(' ', '-');
    },
    handleChangeMenuIconState(event) {
      this.toggleMenu('expandableMenuOptions', event);
    },
    toggleMenu(menuId, open) {
      this.setMenuIsOpen(open);
      this.toggleExpandableElement(menuId);
    },
    setMenuIsOpen(value) {
      this.menuIsOpen = value;
    },
    toggleExpandableElement(expandableElementId) {
      const expandableElement = document.getElementById(expandableElementId);

      if (expandableElement.style.maxHeight) {
        expandableElement.style.maxHeight = null;
      } else {
        expandableElement.style.maxHeight = `${expandableElement.scrollHeight}px`;
      }
    },
  },
};
</script>

<style scoped lang="scss">
.menu {
  height: 100%;

  &__logo-image {
    max-width: 100%;
  }

  &__options {
    max-height: 0px;
    overflow: hidden;
    transition: max-height 0.3s ease-out;
    display: flex;
    flex-direction: column;
    text-align: center;
    cursor: pointer;
  }

  &__option {
    padding: 5px;
    text-decoration: none;
    color: #e4e1d4;
  }

  &__option:lang(ru) {
    font-family: Capture-it;
    font-size: 1.4em;
  }

  &__bar {
    height: 75px;
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  &__part {
    flex-basis: 0;
    flex-grow: 3;
    display: flex;
    justify-content: flex-end;
    margin: 0 10px 0 10px;
  }

  &__logo {
    max-height: 100%;
    width: 250px;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;

    &-image {
      max-width: 100%;
    }
  }
}
</style>
